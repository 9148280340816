<template>
  <div>
    <div class="d-flex flex-column-fluid">
      <!--begin::Container-->
      <div class="container">
        <!--begin::Card-->
        <div class="card card-custom">
          <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
              <h3 class="card-label">Détail article : {{ post.title }}</h3>
            </div>
          </div>

          <div class="card-body">
            <div class="row">
              <div class="col-md-12">
                <img
                  v-if="post.media.url == null"
                  src="images/togo-home.jpg"
                  alt=""
                />
                <img v-else :src="post.media.url" width="100%" alt="" />
              </div>
              <div class="col-md-12">
                <p v-html="post.content"></p>
              </div>
            </div>
          </div>
        </div>
        <!--end::Card-->
      </div>
      <!--end::Container-->
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";

export default {
  name: "Show",
  data() {
    return {
      initalize: "post/" + this.$route.params.id,
      post: {}
    };
  },

  beforeMount() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      let vm = this;
      ApiService.get(this.initalize).then(function(response) {
        vm.post = response.data.post;
      });
    }
  }
};
</script>

<style scoped></style>
